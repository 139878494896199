import styled, { css, keyframes } from "styled-components";
import { Box } from "@cruk/cruk-react-components";

export const RequiredParagraph = styled.p`
  font-weight: ${({ theme }) => theme.typography.fontWeightHeavy};
  span {
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }
`;

export const widthTabletStyles = css`
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: 70%;
  }
`;

export const ExtendedBoxWrapper = styled(Box)`
  ${widthTabletStyles};
`;

export const ButtonLink = styled.button`
  background: none;
  padding: 0;
  margin: -${({ theme }) => theme.spacing.xxs} 0 ${({ theme }) => theme.spacing.xs};
  border: 0;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

export const InputsLayoutWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    width: 70%;
  }
`;

const Rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.span`
  border: 3px solid ${({ theme }) => theme.tokenColors.grey_200};
  border-top: 3px solid ${({ theme }) => theme.tokenColors.grey_300};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${Rotate} 1s linear infinite;
  position: relative;
  text-align: center;
  margin: auto;
  top: 5px;
  display: block;
`;

export const PageContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.m} ${({ theme }) => theme.spacing.s};
`;

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.utilities.contentMaxWidth};
`;

export const StyledCollapse = styled.div`
  button {
    min-height: 0;
  }
`;
