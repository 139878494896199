import { createContext, Dispatch, SetStateAction } from "react";

const initialValue: {
  formVersion: string;
  setFormVersion: Dispatch<SetStateAction<string>>;
} = {
  formVersion: "",
  setFormVersion() {},
};

const VersionsContext = createContext(initialValue);

export default VersionsContext;
