import { ReactElement } from "react";
import styled from "styled-components";
import {
  internationalizePhoneNumber,
  removeMiddleSpaces,
} from "../helpers/functions/shared";

const PhoneNumberMobile = styled.a`
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: none;
  }
`;

const PhoneNumberDesktop = styled.span`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: inline;
  }
`;

type PhoneNumberProps = {
  countryCallingCode?: string;
  children: string;
};

const PhoneNumber = ({
  children,
  countryCallingCode,
}: PhoneNumberProps): ReactElement => (
  <>
    <PhoneNumberMobile
      href={`tel:${internationalizePhoneNumber(
        removeMiddleSpaces(children),
        countryCallingCode
      )}`}
    >
      {children}
    </PhoneNumberMobile>
    <PhoneNumberDesktop>{children}</PhoneNumberDesktop>
  </>
);

export default PhoneNumber;
