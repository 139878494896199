import {
  useState,
  createContext,
  ReactElement,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { Client, OptimizelyUserContext } from "@optimizely/optimizely-sdk";
import {
  getOptimizelyAudience,
  setOptimizelyAudience,
} from "../helpers/functions/shared";
import type { Audience } from "../helpers/functions/shared";

type OptimizelyContextType = {
  optimizely: Client | null;
  optimizelyUserContext: OptimizelyUserContext | null;
  optimizelyAudience: Audience;
  isTestAudience: boolean;
  loadingState: "loading" | "ready" | "failed";
};

export type OptimizelyContextProviderType = [
  OptimizelyContextType,
  Dispatch<SetStateAction<OptimizelyContextType>>
];

type OptimizelyProviderPropTypes = {
  children?: ReactElement | ReactElement[];
};

const defaultState = (): OptimizelyContextType => ({
  optimizely: null,
  optimizelyUserContext: null,
  optimizelyAudience: getOptimizelyAudience(),
  get isTestAudience() {
    return this.optimizelyAudience === "test";
  },
  loadingState: "loading",
});

export const OptimizelyContext = createContext<OptimizelyContextProviderType>([
  defaultState(),
  () => {},
]);

export const OptimizelyContextProvider = ({
  children,
}: OptimizelyProviderPropTypes): ReactElement => {
  const [state, setState] = useState(defaultState());

  useEffect(() => {
    setOptimizelyAudience(state.optimizelyAudience);

    if (state.optimizelyUserContext) {
      state.optimizelyUserContext.setAttribute(
        "audience",
        state.optimizelyAudience
      );
      state.optimizelyUserContext.setAttribute(
        "testAudience",
        state.isTestAudience
      );
    }
  }, [!!state.optimizelyUserContext, state.optimizelyAudience]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <OptimizelyContext.Provider value={[state, setState]}>
      {children}
    </OptimizelyContext.Provider>
  );
};
