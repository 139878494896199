import { useContext, useEffect, useState } from "react";
import { createInstance, setLogLevel } from "@optimizely/optimizely-sdk";
import {
  getOptimizelyUserId,
  optimizelyNotificationListeners,
  checkOptimizelyVariation,
  getOptimizelyAudience,
  fetchOptimizelyDatafile,
} from "../helpers/functions/shared";
import type { FetchOptimizelyDatafileResponse } from "../helpers/functions/shared";
import { OptimizelyContext } from "../contexts/OptimizelyContext";

const OptimizelySetup = () => {
  // OPTIMIZELY SET UP
  const OPTIMIZELY_TIMEOUT_IN_MS = 5000;

  const [datafileResponse, setDatafileResponse] =
    useState<FetchOptimizelyDatafileResponse>(null);

  const [optimizelyContext, setOptimizelyContext] =
    useContext(OptimizelyContext);

  const optimizelyUserId = getOptimizelyUserId();
  const { optimizelyAudience, isTestAudience } = optimizelyContext;

  useEffect(() => {
    if (!optimizelyContext.optimizely) {
      fetchOptimizelyDatafile(
        process.env.OPTIMIZELY_SDK_KEY || "R9E9JzGFDYYRudsUa9aP9",
        OPTIMIZELY_TIMEOUT_IN_MS
      )
        .then((data) => {
          setDatafileResponse(data);
        })
        .catch(console.error);
    }
  }, [!!optimizelyContext.optimizely]);

  useEffect(() => {
    if (datafileResponse) {
      const optimizelyClient = createInstance({
        sdkKey: process.env.OPTIMIZELY_SDK_KEY || "R9E9JzGFDYYRudsUa9aP9",
        datafile: datafileResponse?.datafile,
        eventBatchSize: 100,
        eventFlushInterval: 3000,
      });

      optimizelyClient
        .onReady({ timeout: OPTIMIZELY_TIMEOUT_IN_MS })
        .then(({ success }) => {
          optimizelyNotificationListeners(optimizelyClient);
          const optimizelyUser = optimizelyClient.createUserContext(
            optimizelyUserId,
            {
              testAudience: isTestAudience,
              audience: optimizelyAudience,
              $opt_user_agent: window.navigator.userAgent,
            }
          );
          checkOptimizelyVariation({ user: optimizelyUser });
          setOptimizelyContext({
            ...optimizelyContext,
            optimizely: optimizelyClient,
            optimizelyUserContext: optimizelyUser,
            optimizelyAudience: getOptimizelyAudience(),
            loadingState: success ? "ready" : "failed",
          });
        })
        .catch((e) => {
          console.error("[OPTIMIZELY] Failure to set up Optimizely client.", e);
        });
      setLogLevel(null);
    }
  }, [datafileResponse]);

  return <></>; // eslint-disable-line react/jsx-no-useless-fragment
};

export default OptimizelySetup;
