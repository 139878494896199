type HeaderConfigType = {
  [name: string]: {
    logoAlt: string;
    logoSrc: string;
    logoTitle: string;
    logoUrl: string;
    siteSlogan?: string;
  };
};

const headerConfig: HeaderConfigType = {
  SU2C: {
    logoAlt: "Stand Up To Cancer logo",
    logoSrc: "/assets/su2c-logo.png",
    logoTitle: "Stand Up To Cancer",
    logoUrl: "https://www.standuptocancer.org.uk/",
  },
  CRUK: {
    logoAlt: "Cancer Research UK logo",
    logoSrc: "/assets/cruk-logo.png",
    logoTitle: "Cancer Research UK",
    logoUrl: "https://www.cancerresearchuk.org/",
    siteSlogan: "Together we will beat cancer",
  },
  NEW_CRUK: {
    logoAlt: "Cancer Research UK logo",
    logoSrc: "/assets/new-cruk-logo.png",
    logoTitle: "Cancer Research UK",
    logoUrl: "https://www.cancerresearchuk.org/",
    siteSlogan: "Together we are beating cancer",
  },
  BMF: {
    logoAlt: "Bobby Moore Fund and Cancer Research UK logos",
    logoSrc: "/assets/bmf-logo.png",
    logoTitle: "Bobby Moore Fund and Cancer Research UK",
    logoUrl: "https://www.cancerresearchuk.org/get-involved/bobby-moore-fund",
    siteSlogan: "",
  },
  RFL: {
    logoAlt: "Race for Life logo",
    logoSrc: "/assets/rfl-logo.png",
    logoTitle: "Race for Life",
    logoUrl: "https://raceforlife.cancerresearchuk.org/",
    siteSlogan: "",
  },
};

export default headerConfig;
