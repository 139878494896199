type DataLayerEvent =
  | {
      event: "dataLayerLoaded";
      brand: string;
      pagePath: string;
      pageTitle: string;
    }
  | {
      event: "eec.checkout";
      ecommerce: {
        checkout: {
          actionField: {
            step: number;
          };
          products: CheckoutProductsType[];
        };
      };
    }
  | {
      event: "interaction";
      action: string;
      label: string;
    }
  | {
      event: "ab_test";
      eventCategory: "ab test";
      eventAction: string;
      eventLabel: string;
    }
  | {
      event: "error";
      errorCode: string;
      errorType: string;
      errorMessage: string | Record<string, unknown>;
    }
  | {
      event: "eec.purchase";
      ecommerce: {
        currencyCode: string;
        purchase: {
          actionField: {
            id: string;
            affiliation: string;
            revenue: number;
            tax: number;
            shipping: number;
            coupon: string;
            payment_type: string;
            source_code: string;
            restriction_code: string;
            revenue_excl_giftaid: number;
          };
          products: CheckoutProductsType[];
        };
      };
    }
  | {
      event: "Optimizely";
      experiment: string;
      enabled: boolean;
      variation: string;
    }
  | {
      event: "OneTrustGroupsUpdated";
      OnetrustActiveGroups: string;
      "gtm.uniqueEventId": number;
    };

export type CheckoutProductsType = {
  id: string;
  name: string;
  category: PaymentCategoryTypes;
  variant?: string;
  brand: string;
  price?: number;
  quantity?: number;
  donation_event_code?: string;
};

export type PaymentCategoryTypes =
  | "fundraising donation"
  | "donation"
  | "event registration"
  | "CRUKECommerce";

type Product = {
  type?: string;
  paymentName?: string;
  paymentCategory: PaymentCategoryTypes;
};

type AccountType = {
  journey: "donation" | "product";
  dataLayer?: Product;
};

const merchantAccounts = new Map<string, AccountType>();

merchantAccounts.set("CRUKECommerce", {
  journey: "product",
  dataLayer: {
    type: "CRUKECommerce",
    paymentCategory: "CRUKECommerce",
  },
});

merchantAccounts.set("CRUKEWSSupled", {
  journey: "product",
  dataLayer: {
    type: "events",
    paymentCategory: "event registration",
  },
});

merchantAccounts.set("CRUKEWSEvents", {
  journey: "product",
  dataLayer: {
    type: "events",
    paymentCategory: "event registration",
  },
});

merchantAccounts.set("CRUKOFDonation", {
  journey: "product",
  dataLayer: {
    type: "fundraising",
    paymentName: "fundraising donation",
    paymentCategory: "donation",
  },
});

merchantAccounts.set("SU2CSingleDonation", {
  journey: "donation",
  dataLayer: { paymentCategory: "donation" },
});

merchantAccounts.set("CRUKSingleDonation", {
  journey: "donation",
  dataLayer: { paymentCategory: "donation" },
});

export const fetchMerchantAccounts = (): Map<string, AccountType> =>
  merchantAccounts;

export const fetchProduct = (merchantAccount: string): Product | undefined =>
  fetchMerchantAccounts().get(merchantAccount)?.dataLayer;

declare global {
  interface Window {
    dataLayer: Array<DataLayerEvent>;
  }
}

const findLast = <T>(
  array: Array<T>,
  predicate: (element: T) => boolean
): T | undefined => {
  for (let i = array.length - 1; i >= 0; i -= 1) {
    if (predicate(array[i])) {
      return array[i];
    }
  }
  return undefined;
};

if (typeof window !== "undefined" && !window.dataLayer) window.dataLayer = [];

const useDataLayer = () => {
  const push = (event: DataLayerEvent) => {
    window.dataLayer.push(event);
  };

  return {
    pushDataLayerLoaded(brand: string, title: string) {
      push({
        event: "dataLayerLoaded",
        brand,
        pagePath: window.location.pathname,
        pageTitle: title,
      });
    },
    pushCheckoutStep(step: number, products: CheckoutProductsType[]) {
      push({
        event: "eec.checkout",
        ecommerce: {
          checkout: {
            actionField: {
              step,
            },
            products,
          },
        },
      });
    },
    pushInteraction(name: string, value: string) {
      push({
        event: "interaction",
        action: name,
        label: value,
      });
    },
    pushDonatePageInteraction(name: string, value: string) {
      push({
        event: "ab_test",
        eventCategory: "ab test",
        eventAction: name,
        eventLabel: value,
      });
    },
    pushError(errorCode: string, errorType: string, errorMessage: string) {
      push({
        event: "error",
        errorCode,
        errorType,
        errorMessage,
      });
    },
    pushPaymentSuccess(
      amount: number,
      revenue: number,
      transactionID: any,
      paymentMethod: string,
      affiliation: string,
      productSource: string,
      restriction: string,
      products: CheckoutProductsType[],
      discount: string
    ) {
      push({
        event: "eec.purchase",
        ecommerce: {
          currencyCode: "GBP",
          purchase: {
            actionField: {
              id: transactionID,
              affiliation,
              revenue,
              tax: 0,
              shipping: 0,
              coupon: discount,
              payment_type: paymentMethod,
              source_code: productSource,
              restriction_code: restriction || "nocode",
              revenue_excl_giftaid: amount,
            },
            products,
          },
        },
      });
    },
    pushOptimizelyExperiment(
      experiment: string,
      enabled: boolean,
      variation: string
    ) {
      const timer = window.setInterval(() => {
        const dl = window.dataLayer;

        const consentEvent = findLast(
          dl,
          (data) => data.event === "OneTrustGroupsUpdated"
        ) as
          | Extract<DataLayerEvent, { event: "OneTrustGroupsUpdated" }>
          | undefined;

        const consentSettings = consentEvent?.OnetrustActiveGroups;
        const hasConsent = consentSettings?.includes("per");

        if (hasConsent) {
          if (enabled) {
            push({
              event: "Optimizely",
              experiment,
              enabled,
              variation,
            });
          }

          cleanUpTimer();
        }
      }, 2000);

      const cleanUpTimer = () => {
        clearInterval(timer);
      };
    },
  };
};

export default useDataLayer;
