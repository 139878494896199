import React, { ReactElement, HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { ThemeType } from "@cruk/cruk-react-components";

type FooterProps = HTMLAttributes<HTMLDivElement> & {
  formTheme: string;
};

type FooterStyleProps = {
  formTheme: string;
  theme?: ThemeType;
};

const StyledFooter = styled.footer`
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.tokenColors.grey_200};
  padding: 0 ${({ theme }) => theme.spacing.s};
  
  ${({ theme, formTheme }: FooterStyleProps) =>
    formTheme === "SU2C" &&
    css`
      border-top: 1px solid ${theme.tokenColors.grey_200};
      background-color: ${theme.tokenColors.white};
    `}
}
`;

const FooterContainer = styled.div`
  max-width: ${({ theme }) => theme.utilities.contentMaxWidth};
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.m} 0;
`;

const FooterLinks = styled.ul`
  padding: 0;
  list-style: none;

  ${({ formTheme }: FooterStyleProps) =>
    formTheme === "CRUK" &&
    css`
      li {
        display: inline-block;

        &:not(:last-child) {
          &::after {
            content: "|";
            margin: 0 1rem;
          }
        }
      }
    `}

  ${({ theme, formTheme }: FooterStyleProps) =>
    formTheme === "SU2C" &&
    css`
      display: flex;
      flex-wrap: wrap;

      li {
        width: calc(50% - 1rem);
        margin-right: 1rem;

        a {
          font-weight: bold;
          text-decoration: none;
          font-size: ${theme.fontSizes.s};

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }

        @media (min-width: ${theme.breakpoint.tablet}) {
          width: calc(25% - 1rem);
          border-right: 1px solid ${theme.tokenColors.grey_200};

          &:nth-child(4n) {
            border-right: 0;
          }
        }
      }
    `}
`;

const FooterText = styled.p`
  ${({ theme, formTheme }: FooterStyleProps) =>
    formTheme === "SU2C" &&
    css`
      color: ${theme.tokenColors.grey_500};
      font-size: ${theme.fontSizes.xs};
    `}
`;

const FooterLogo = styled.div`
  display: block;

  img {
    width: 165px;
    height: 52px;
  }

  ${({ theme, formTheme }: FooterStyleProps) =>
    formTheme === "SU2C" &&
    css`
      @media (min-width: ${theme.breakpoint.tablet}) {
        float: right;
        margin-left: 1rem;
      }
    `}
`;

const Footer = ({ formTheme }: FooterProps): ReactElement => (
  <StyledFooter formTheme={formTheme}>
    <FooterContainer>
      <FooterLinks formTheme={formTheme}>
        {formTheme === "SU2C" ? (
          <>
            <li>
              <a
                href="https://www.standuptocancer.org.uk/information/terms-and-conditions"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
            </li>
            <li>
              <a
                href="https://www.standuptocancer.org.uk/information/privacy"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://www.standuptocancer.org.uk/cookies-policy"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookies
              </a>
            </li>
            <li>
              <a
                href="https://www.standuptocancer.org.uk/information/safety"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              >
                Safety
              </a>
            </li>
            <li>
              <a
                href="https://www.standuptocancer.org.uk/accessibility"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              >
                Accessibility
              </a>
            </li>
            <li>
              <a
                href="http://www.cancerresearchuk.org"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              >
                Cancer Research UK
              </a>
            </li>
            <li>
              <a
                href="https://www.standuptocancer.org.uk/information/press-enquiries"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              >
                Press Enquiries
              </a>
            </li>
            <li>
              <a
                href="http://www.cancerresearchuk.org/funding-for-researchers"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              >
                Funding for researchers
              </a>
            </li>
            <li>
              <a
                href="http://www.cancerresearchuk.org/support-us/volunteer/become-a-media-volunteer"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              >
                Share your story
              </a>
            </li>
            <li>
              <a
                href="https://www.standuptocancer.org.uk/partners"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              >
                Partners
              </a>
            </li>
          </>
        ) : (
          <>
            <li>
              <a
                href="https://www.cancerresearchuk.org/about-cancer/about-our-information"
                target="_blank"
                rel="noopener noreferrer"
              >
                About our information
              </a>
            </li>
            <li>
              <a
                href="https://www.cancerresearchuk.org/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and conditions
              </a>
            </li>
            <li>
              <a
                href="https://www.cancerresearchuk.org/privacy-statement"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>
            </li>
            <li>
              <a
                href="https://www.cancerresearchuk.org/modern-slavery-transparency-statement"
                target="_blank"
                rel="noopener noreferrer"
              >
                Modern Slavery Statement
              </a>
            </li>
            <li>
              <a
                href="https://www.cancerresearchuk.org/terms-and-conditions/cookies-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookies
              </a>
            </li>
            <li>
              <a
                href="https://www.cancerresearchuk.org/accessibility"
                target="_blank"
                rel="noopener noreferrer"
              >
                Accessibility
              </a>
            </li>
            <li>
              <a
                href="https://www.cancerresearchuk.org/sitemap"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sitemap
              </a>
            </li>
          </>
        )}
      </FooterLinks>
      <FooterLogo formTheme={formTheme}>
        <a
          href="https://www.fundraisingregulator.org.uk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/assets/fund-reg.svg" alt="Fundraising Regulator logo" />
        </a>
      </FooterLogo>
      <FooterText formTheme={formTheme}>
        {formTheme === "SU2C"
          ? "Stand Up To Cancer and Stand Up To Cancer Brand Marks are registered trademarks of the Entertainment Industry Foundation. Cancer Research UK is a registered charity in England and Wales (1089464), Scotland (SC041666), the Isle of Man (1103) and Jersey (247). A company limited by guarantee. Registered company in England and Wales (4325234) and the Isle of Man (5713F). Registered address: 2 Redman Place, London, E20 1JQ. Donations will be made to Cancer Research UK in support of the Stand Up To Cancer campaign."
          : "Cancer Research UK is a registered charity in England and Wales (1089464), Scotland (SC041666), the Isle of Man (1103) and Jersey (247). A company limited by guarantee. Registered company in England and Wales (4325234) and the Isle of Man (5713F). Registered address: 2 Redman Place, London, E20 1JQ."}
      </FooterText>
    </FooterContainer>
  </StyledFooter>
);

export default Footer;
