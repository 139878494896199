export const addressLine1RegExp = /^[a-zA-Z0-9-/.,' &]+$/;
export const addressLine23RegExp = /^[a-zA-Z0-9-/.,' ]+$/;
export const amountRegExp = /^[0-9]\d*(\.\d+)?$/;
export const andRegExp = /(^and | and | and$|& | &|\+ | \+)/;
export const cityRegExp = /^[a-zA-Z-/.' ]+$/;
export const emailRegExp = /^[^@]+@[^@]+\.[^@]+$/;
export const extraEmailRegExp = /[’',;*<>~¿£!¡€]+/;
export const nameRegExp =
  /^[a-zA-Z ’'\-ƼƽÁáÀàȦȧÂâÄäǍǎĂăĀāÃãÅåĄąȺǠǡǺǻǞǟȀȁȂȃɐɑɒÆæǼǽǢǣƀɃƁɓƂƃƄƅĆćĊċĈĉČčÇçȻȼƇƈƆɔɕĎďĐđƋƌƊɗÐðƍȸǱǲǳǄǅǆƉɖȡÉéÈèĖėÊêËëĚěĔĕĒēĘęȨȩɆɇȄȅȆȇƐɛƎǝƏəɚɘɜɝɞȜȝƑƒǴǵĠġĜĝǦǧĞğĢģǤǥƓƔĤĥȞȟĦħƕǶıÍíÌìİÎîÏïǏǐĬĭĪīĨĩĮįƗȈȉȊȋƖĲĳȷĴĵǰɈɉɟĸǨǩĶķƘƙĹĺĿŀĽľĻļƚȽŁłƛǇǈǉȴƜŃńǸǹŇňÑñŅņƝŉƞȠǊǋǌȵŊŋÓóÒòȮȯÔôÖöǑǒŎŏŌōÕõǪǫŐőƟØøȰȱȪȫǾǿȬȭǬǭȌȍȎȏƠơƢƣŒœȢȣƤƥɊɋȹƦŔŕŘřŖŗɌɍȐȑȒȓŚśŜŝŠšŞşȘșȿƩƧƨƪßſŤťŢţƬƭƫƮȚțȾȶÞþŦŧÚúÙùÛûÜüǓǔŬŭŪūŨũŮůŲųŰűɄǗǘǛǜǙǚǕǖȔȕȖȗƯưƱƲɅŴŵƿǷÝýŶŷÿŸȲȳɎɏƳƴŹźŻżŽžƵƶȤȥɀƷǮǯƸƹƺƾɁɂ]*$/;
export const nameExcludedRegExp =
  /(^|\W)(test|unknown|none|anon|anonymous|undefined)($|\W)/i;
export const postcodeIntlRegExp = /^[a-zA-Z0-9 ]+$/;
export const postcodeUkRegExp =
  /^ *(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2})) *$/i;
export const singleCharRegExp = /^[a-zA-Z0-9-/.'&],$/;
export const whiteSpaceRegExp = /\s/g;
export const phoneRegExp =
  /^((\+?[0-9]{10,15})|(01[0-9]{3}\s?[0-9]{5,6})|(011[0-9]\s?[0-9]{3}\s?[0-9]{4})|(01[0-9]1\s[0-9]{3}\s[0-9]{4})|((013873|015242|01539[456]|01697[34]|01768[347]|019467)\s?[0-9]{5})|(016977\s[0-9]{4,5})|(02[0-9]\s?[0-9]{4}\s?[0-9]{4})|(02[0-9]{2}\s?[0-9]{3}\s?[0-9]{4})|(07[0-9]{3}\s?[0-9]{6}))$/;
export const mobileRegExp = /^(07\d{8,12})$/;
export const numbersOnlyRegExp = /^[0-9]*$/;
export const sortCodeRegExp = /^[0-9- ]*$/;
export const bracketSelectorRegExp = /([[\]])/g;
export const urlRegExp =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/;
