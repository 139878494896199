import React, { useContext, ReactElement } from "react";
import Head from "next/head";
import Script from "next/script";
import VersionsContext from "../contexts/VersionsContext";

type FormHeadProps = {
  page: string;
  title: string;
  optimizelyId: string;
};

const FormHead = ({
  page,
  title,
  optimizelyId,
}: FormHeadProps): ReactElement => {
  const { formVersion } = useContext(VersionsContext);
  return (
    <>
      <Script
        src={`https://cdn-pci.optimizely.com/js/${optimizelyId}.js`}
        strategy="afterInteractive"
      />
      {page === "Payment" && formVersion !== "regular" ? (
        <Script
          src="https://pay.google.com/gp/p/js/pay.js"
          strategy="afterInteractive"
        />
      ) : (
        ""
      )}

      <Head>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta
          name="description"
          content="At Cancer Research UK we pioneer research to bring forward the day when all cancers are cured, our progress depends on your donations."
        />
        {page !== "Donate" ? <meta name="robots" content="noindex" /> : ""}
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta httpEquiv="cleartype" content="on" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="Cancer Research UK" />
        <meta
          property="og:description"
          content="At Cancer Research UK we pioneer research to bring forward the day when all cancers are cured, our progress depends on your donations."
        />
        <meta
          property="og:url"
          content="https://donate.cancerresearchuk.org/"
        />
        <meta name="twitter:card" content="summary" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/assets/favicons/apple-touch-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/assets/favicons/apple-touch-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/assets/favicons/apple-touch-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/assets/favicons/apple-touch-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/assets/favicons/apple-touch-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/assets/favicons/apple-touch-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/assets/favicons/apple-touch-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/assets/favicons/apple-touch-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/assets/favicons/apple-touch-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/assets/favicons/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/assets/favicons/favicon-194x194.png"
          sizes="194x194"
        />
        <link
          rel="icon"
          type="image/png"
          href="/assets/favicons/favicon-32x32.png"
          sizes="96x96"
        />
        <link
          rel="icon"
          type="image/png"
          href="/assets/favicons/android-chrome-192x192.png"
          sizes="192x192"
        />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="icon"
          type="image/png"
          href="/assets/favicons/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="shortcut icon"
          href="/assets/favicons/favicon.ico"
          type="image/x-icon"
        />
      </Head>
    </>
  );
};

export default FormHead;
